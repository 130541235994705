import React, { Component } from "react"
import { navigate } from "gatsby"
import Seo from '../components/seo';

const isBrowser = typeof window !== "undefined";

class Front extends Component {
  render() {
    if (isBrowser) {
      navigate("/")
    }
    return (
      <>
        <Seo
          title={"Home"}
          pathname={"/front/"}
          article={false}
        />
      </>
    );
  }
}

export default Front
